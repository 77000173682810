<template>
  <b-card>
    <h4 class="mb-2">
      Чек-листы
    </h4>

    <div
      class="mb-2"
    >
      <BOverlay :show="buzy">
        <vSelect
          v-if="!buzy"
          v-model="selectModel"
          :options="availableOptions"
          label="name"
          placeholder="Добавить чек-лист..."
          :reduce="(option) => option.id"
          multiple
          :disabled="isRequestStatusCompleted ||
            !$store.getters['user/permission']('requests:update')"
          @input="$emit('updateField', { field: 'checklists_ids', value: $event, update: true })"
        >
          <template #no-options="{}">
            Ничего не найдено
          </template>
        </vSelect>
      </BOverlay>
    </div>

    <div class="d-flex gap-2">
      <BFormCheckbox
        v-model="clientSign"
        class="custom-control-primary"
        name="check-button"
        switch
        :disabled="isRequestStatusCompleted ||
          !$store.getters['user/permission']('requests:update')"
      />
      <div>Подпись клиента</div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BFormCheckbox, BOverlay } from 'bootstrap-vue';
import { ref, computed, toRefs } from '@vue/composition-api';
import vSelect from 'vue-select';
import { getChecklistsForSelect } from '@/store/checklists/checklists.api';

export default {
  components: {
    BCard,
    BFormCheckbox,
    vSelect,
    BOverlay,
  },
  props: {
    item: Object,
    isRequestStatusCompleted: Boolean,
  },
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const options = ref([]);
    const checklists = ref([]);
    const value = ref([]);
    const selectModel = ref(item.value.checklists_ids || []);
    const buzy = ref(true);
    getChecklistsForSelect().then((res) => {
      checklists.value = res?.data?.response || [];
      // eslint-disable-next-line max-len
      value.value = checklists.value.filter((i) => (item.value.checklists_ids || []).includes(i.id));
      buzy.value = false;
    });

    // eslint-disable-next-line max-len
    const availableOptions = computed(() => checklists.value.filter((opt) => value.value.indexOf(opt.id) === -1));

    const getChecklistById = (id) => checklists.value.find((i) => i.id === id);

    const clientSign = computed({
      get() {
        return item.value.client_sign_required;
      },
      set(value) {
        if (value !== item.value.client_sign_required) {
          emit('updateField', { field: 'client_sign_required', value });
        }
        return value;
      },
    });

    return {
      options,
      availableOptions,
      value,
      getChecklistById,
      checklists,
      selectModel,
      clientSign,
      buzy,
    };
  },
};
</script>

<style lang="sass">
.checklists-select
  .b-form-tag-content
    font-size: 12px
  .badge
    margin-right: 0
  .list-inline-item
    margin: 0 10px 6px 0
  ul
    overflow: hidden
  ul, li
    max-width: 100%
  option
    cursor: pointer
  .vs__dropdown-menu
    overflow-y: auto
    max-height: 350px
    li
      padding-right: 20px !important
      overflow: hidden !important
      text-overflow: ellipsis

.v-select.vs--disabled
    .vs__selected
      background-color: #A49CF5 !important

.dark-layout .v-select:not(.vs--single) .vs__selected
  color: white!important
</style>
